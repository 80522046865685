<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: 王东润
 * @Date: 2023-11-06 17:19:56
 * @LastEditors: 王东润
 * @LastEditTime: 2023-11-10 15:01:07
 * @FilePath: \yxb-pc\src\views\navMenu.vue
-->
<template>
  <div ref="nav" class="nav"
    :class="{
      'bgc_white': hoverFlag || (scrollTop > 20 && (acName !== 'course' || acName !== 'about')),
      'nav_tra': (acName === 'course' || acName === 'about') && scrollTop <= 20 && !hoverFlag
    }"
    @mouseenter="hoverFlag = true"
    @mouseleave="hoverFlag = false">
    <div class="nav_main">
      <div class="logo"
        @click="goHome" />
      <ul class="nav_ul">
        <li
          v-for="(item, index) in list"
          :key="index"
          class="nav_li"
          @click="activeItem(item)">
          <span
            :class="item.name === acName ? 'active_span': ''"
            class="nav_span">{{ item.title }}</span>
          <div
            v-if="item.children"
            class="children_box">
            <div
              class="children_item"
              v-for="(subItem, subIndex) in item.children"
              :key="subIndex"
              @click="activeSubItem(subItem, item)">
              {{ subItem.title }}
            </div>
          </div>
        </li>
      </ul>
      <div class="btns">
        <el-popover
          placement="bottom"
          trigger="hover">
          <div
            class="popover_card">
            <div
              v-for="(cardItem, cardIndex) in popoverList"
              :key="cardIndex"
              class="card_item"
              :class="cardIndex && 'ml_12'">
              <img
                class="card_url"
                :src="cardItem.url" />
              <div
                class="card_name">
                {{ cardItem.name }}
              </div>
            </div>
          </div>
          <el-button
            class="left_btn title_btn"
            type="primary"
            slot="reference"
            plain
            round>APP下载</el-button>
        </el-popover>
        <el-button
          class="title_btn bg_color"
          type="primary" round
          @click="openBlue">申请试用</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      // 距离顶部距离
      scrollTop: 0,
      // 移入背景
      hoverFlag: false,
      // 选中下标
      acName: 'home',
      // nav数据
      list: [
        {
          title: '首页',
          name: 'home'
        },
        {
          title: '产品解决方案',
          name: '',
          children: [
            {
              title: '学历继续教育服务平台',
              name: 'programme'
            },
            {
              title: '职学宝培训平台',
              name: 'train'
            }
          ]
        },
        {
          title: '课程服务',
          name: 'course'
        },
        {
          title: '关于我们',
          name: 'about'
        }
      ],
      popoverList: [
        {
          url: require('@/assets/xl_icon.png'),
          name: '学历继续教育服务'
        },
        {
          url: require('@/assets/fxl_icon.png'),
          name: '职学宝培训'
        }
      ],
      input: '',
      textarea: ''
    }
  },
  computed: {
    ...mapGetters(['activeName'])
  },
  watch: {
    // 监听激活路由name
    activeName: {
      handler(val) {
        if (val === 'programme' || val === 'train') this.list[1].name = val
        this.acName = val
      },
      immediate: true
    }
  },
  mounted() {
    // 监听滚动条距离
    this.$nextTick(function () {
      window.addEventListener('scroll', this.handleScroll)
    })
  },
  methods: {
    // 高亮跳转
    activeItem(item) {
      if (item.name) {
        this.$store.dispatch('set_activename', item.name)
        this.$router.push({ name: item.name })
      }
    },
    // 跳转首页
    goHome() {
      this.$store.dispatch('set_activename', 'home')
      this.$router.push({ name: 'home' })
    },
    activeSubItem(subItem, item) {
      item.name = subItem.name
      this.$store.dispatch('set_activename', subItem.name)
      this.$router.push({ name: subItem.name })
    },
    // 赋值滚动条距离
    handleScroll() {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      this.scrollTop = scrollTop
    },
    // 打开弹窗
    openBlue() {
      this.$store.dispatch('set_blueFlag', true)
    }
  },
  destroy() {
    // 取消监听
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="scss" scoped>
.pc {
  .bgc_white {
    background-color: rgb(255, 255, 255) !important;
    box-shadow: 0px 0px 10px 0px rgb(0, 0, 0, 0.1);
  }
  .bgc_tra {
    background-color: #3b7bfc !important;
  }
  .nav {
    z-index: 99;
    height: 68px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    position: fixed;
    top: 0;
    .nav_main {
      margin: 0 auto;
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo {
        width: 12.5%;
        height: 40px;
        background: url(@/assets/logo1.png) no-repeat center;
        cursor: pointer;
      }
      .nav_ul {
        height: 100%;
        display: flex;
        align-content: center;
        padding: 0 5%;
        .nav_li {
          position: relative;
          display: flex;
          justify-content: center;
          cursor: pointer;
          .nav_span {
            display: flex;
            align-items: center;
            margin: 0 40px;
            color: #666666;
            border-bottom: none;
          }
          .active_span {
            border-bottom: 3px solid #ff616d !important;
            color: #000;
          }
          .children_box {
            display: none;
            position: absolute;
            top: 68px;
            width: 200px;
            background: #ffffff;
            opacity: 0.9;
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            line-height: 16px;
            .children_item {
              width: 100%;
              height: 50px;
              line-height: 50px;
              text-align: center;
            }
            .children_item:hover {
              color: #3b7bfc;
            }
          }
        }
        .nav_li:hover {
          .children_box {
            display: block !important;
          }
        }
      }
      .btns {
        display: flex;
        align-items: center;
        .left_btn {
          margin-right: 30px;
        }
        .title_btn {
          height: 36px;
          width: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .bg_color {
          background-color: #3b7bfc;
        }
      }
    }
  }
  .nav_tra {
    background-color: transparent;
    .logo {
      background: url(@/assets/logo2.png) no-repeat center !important;
    }
    .nav_span {
      color: #fff !important;
    }
    .active_span {
      color: #fff !important;
    }
    .children_box {
      background: #3b7bfc !important;
      color: #fff !important;
      opacity: 0.9 !important;
    }
    .left_btn {
      background-color: transparent !important;
      color: #fff;
    }
    .bg_color {
      background-color: #fff !important;
      color: #3b7bfc;
    }
  }
}
.popover_card {
  display: flex;
  .card_item {
    .card_url {
      height: 124px;
      width: 124px;
    }
    .card_name {
      font-size: 14px;
      font-weight: 400;
      color: #555555;
      line-height: 14px;
      margin-top: 12px;
      text-align: center;
    }
  }
}
.title_box {
  height: 140px;
  width: 100%;
  background: url(@/assets/d1.png) no-repeat center;
  padding-left: 60px;
  padding-top: 28px;
  .title_text {
    font-size: 32px;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
  }
}
.body_text {
  color: #3b7bfc;
  font-size: 12px;
  margin-bottom: 20px;
}
.btn_box {
  margin-top: 38px;
  display: flex;
  justify-content: center;
  .body_btn {
    width: 180px;
    height: 56px;
    background: #3b7bfc;
    border-radius: 6px 6px 6px 6px;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;
  }
}
// .el-menu  ------------------
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
.el_menu ::v-deep .el-submenu__title {
  height: 68px !important;
  line-height: 68px !important;
}
.el-menu--horizontal > .el-menu-item {
  height: 68px;
  line-height: 68px;
  padding: 0 40px !important;
}
.el_menu ::v-deep .el-submenu.is-active .el-submenu__title {
  border-bottom: 3px solid #ff616d !important;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 3px solid #ff616d !important;
}
.el_menu ::v-deep .el-submenu.is-active .el-submenu__title:hover {
  background-color: #fff !important;
}
.el_menu ::v-deep .el-submenu .el-submenu__title:hover {
  background-color: #fff !important;
}
.el-menu--horizontal > .el-menu-item.is-active:hover {
  background-color: #fff !important;
}
.el-menu--horizontal > .el-menu-item:hover {
  background-color: #fff !important;
}
</style>
