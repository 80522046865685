<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: 王东润
 * @Date: 2023-11-06 14:42:24
 * @LastEditors: smallWhite
 * @LastEditTime: 2024-03-21 16:38:49
 * @FilePath: /yxb-gw/src/App.vue
-->
<template>
  <div id="app"
    :class="personInfoClass">
    <Navmenu v-if="!phone" />
    <navMenuMobile
      v-if="phone" />
    <Backtotop
      v-if="!phone" />
    <Dialog />
    <router-view />
  </div>
</template>
<script>
import Navmenu from '@/components/navMenu.vue'
import navMenuMobile from '@/components/navMenu_mobile.vue'
import Backtotop from '@/components/backToTop.vue'
import Dialog from '@/components/Dialog.vue'

export default {
  name: 'App',
  components: {
    Navmenu,
    Backtotop,
    Dialog,
    navMenuMobile
  },
  data() {
    return {
      phone: false
    }
  },
  computed: {
    personInfoClass() {
      // const str = JSON.parse(localStorage.getItem('phone')) ? 'mobile' : 'pc'
      const strs = this.$store.state.isMobile ? 'mobile' : 'pc'
      return strs
    }
  },
  watch: {
    $route(val) {
      console.log(val, '020202')
      this.phone = JSON.parse(localStorage.getItem('phone'))
    }
  },
  mounted() {
    if (this._isMobile()) {
      window.localStorage.setItem('phone', true)
      this.$store.commit('SET_ISMOBILE', true)
    } else {
      window.localStorage.setItem('phone', false)
      this.$store.commit('SET_ISMOBILE', false)
    }
    console.log(this.$store.state.isMobile, '0202022222')
  },
  methods: {
    _isMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    }
  }
}
</script>
<style>
#app {
  overflow: hidden;
}
.pc {
  font-family: 'Source_font';
}
</style>
