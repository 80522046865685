/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: 王东润
 * @Date: 2023-11-06 14:42:24
 * @LastEditors: 王东润
 * @LastEditTime: 2023-11-10 13:49:11
 * @FilePath: \yxb-pc\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import BaiduMap from 'vue-baidu-map'
import './assets/font/font.css'

import 'element-ui/lib/theme-chalk/index.css'
import './assets/style/reset.css'
import './assets/style/common.css'
// 重复跳转
import Router from 'vue-router'
const routerPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error)
}
Vue.use(ElementUI)
Vue.use(BaiduMap, {
  ak: 'IwatH8ut7ZyMQGy5PNWQ2v7tRYovMadB'
})
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
