<template>
    <div class="box">
        <div
            v-for="(item, index) in list"
            :key="index"
            @mouseenter="handleEnter(index)"
            @mouseleave="handleLeave"
        >
            <el-popover
                placement="right"
                width="140"
                trigger="hover"
            >
                <div v-if="item.type === 1" class="popover_box">
                    <div class="title">
                        {{ item.text }}
                    </div>
                    <div
                        v-for="(phoneItem, phoneIndex) in item.phone"
                        :key="phoneIndex"
                        class="phone_item"
                    >
                        <i class="el-icon-phone" />
                        {{ phoneItem }}
                    </div>
                </div>
                <img v-if="item.type === 3" class="popover_zp" :src="item.url" />
                <div v-if="item.type === 2" class="popover_card">
                    <div
                        v-for="(cardItem, cardIndex) in item.zp"
                        :key="cardIndex"
                        class="card_item"
                        :class="cardIndex && 'mt_12'"
                    >
                        <img class="card_url" :src="cardItem.url" />
                        <div class="card_name">{{ cardItem.name }}</div>
                    </div>
                </div>
                <div class="box_item" :class="activeIndex === index && 'active_bgc'" slot="reference">
                    <img class="box_icon" :src="item.icon" alt="">
                    <div class="box_text">{{ item.text }}</div>
                </div>
            </el-popover>
        </div>
    </div>
</template>

<script>

export default {
  data () {
    return {
      activeIndex: 99,
      list: [
        {
          text: '商务合作',
          icon: require('@/assets/t1.png'),
          type: 1,
          phone: ['13304019690', '13304157070']
        },
        {
          text: '客服热线',
          icon: require('@/assets/t2.png'),
          type: 1,
          phone: ['400-168-0910', '15104150910']
        },
        {
          text: '商务二维码',
          icon: require('@/assets/t3.png'),
          type: 2,
          zp: [
            {
              url: require('@/assets/he.png'),
              name: '何经理'
            },
            {
              url: require('@/assets/xyy.png'),
              name: '咸经理'
            }
          ]
        },
        {
          text: '客服二维码',
          icon: require('@/assets/t4.png'),
          type: 3,
          url: require('@/assets/kf.png')
        }
      ]
    }
  },
  methods: {
    // 鼠标移入
    handleEnter (index) {
      this.activeIndex = index
    },
    // 鼠标移出
    handleLeave () {
      this.activeIndex = 99
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
    z-index: 99;
    position: fixed;
    bottom: 100px;
    right: 20px;
    width: 80px;
    height: 326px;
    background: #F2F6FF;
    box-shadow: 0px 0px 10px 0px rgba(59,123,252,0.2);
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    .box_item {
        width: 80px;
        height: 80px;
        background: #FFFFFF;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        margin: 1px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        .box_icon {
            width: 32px;
            height: 32px;
            margin: 12px 0;
        }
        .box_text {
            font-size: 12px;
            font-weight: 400;
            color: #333333;
            line-height: 12px;
        }
    }
    .active_bgc {
        background: #E8F0FF !important;
    }
}
.popover_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
        width: 100%;
        text-align: center;
        color: #555555;
        padding-bottom: 10px;
        border-bottom: 1px dashed #DAE6FF;
    }
    .phone_item {
        color: #3B7BFC;
        margin-top: 10px;
    }
}
.popover_zp {
    height: 124px;
    width: 124px;
    background-color: #3B7BFC;
}
.popover_card {
    .card_item {
        .card_url {
            height: 124px;
            width: 124px;
        }
        .card_name {
            font-size: 14px;
            font-weight: 400;
            color: #555555;
            line-height: 14px;
            margin-top: 12px;
            text-align: center;
        }
    }
}
</style>
