<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: 王东润
 * @Date: 2023-11-06 14:42:24
 * @LastEditors: smallWhite
 * @LastEditTime: 2024-03-16 14:53:59
 * @FilePath: /yxb-gw/src/components/Dialog.vue
-->
<template>
  <div class="dialog_box">

    <el-dialog
      class="dialog_one"
      :visible.sync="blueVisible"
      :width="phone == true? '80%':'620px'"
      :modal-append-to-body="false"
      :before-close="handleClose">
      <span slot="title">
        <div class="title_box"
          :class="phone?'phone':''">
          <div
            class="title_text ">
            您好，</div>
          <div
            class="title_text">
            未来的合作伙伴</div>
        </div>
      </span>
      <div class="body_text"
        :class="phone?'phone':''">
        <i
          class="el-icon-warning mr_4" />
        <span>请完善您的信息，让我们更好地为您服务~</span>
      </div>
      <template v-if="!phone">
        <el-input
          v-model="form.name"
          class="w_48 mrp_4"
          placeholder="请输入您的姓名" />
        <el-input
          v-model="form.phone"
          class="w_48"
          placeholder="请输入您的手机号" />
        <el-input
          class="mt_12"
          type="textarea"
          resize="none"
          :rows="2"
          placeholder="请输入您所在的工作单位"
          v-model="form.address" />
      </template>
      <template v-else>
        <el-input
          v-model="form.name"
          class=""
          style="margin-bottom: 10px;"
          placeholder="请输入您的姓名" />
        <el-input
          v-model="form.phone"
          class=""
          placeholder="请输入您的手机号" />
        <el-input
          class="mt_12"
          type="textarea"
          resize="none"
          :rows="2"
          placeholder="请输入您所在的工作单位"
          v-model="form.address" />
      </template>
      <div class="btn_box"
        :class="phone?'phone':''">
        <el-button
          class="body_btn"
          type="primary"
          v-if="!phone"
          @click="submitForm">立即提交</el-button>
        <el-button
          type="primary"
          style="width: 100%;"
          v-if="phone"
          @click="submitForm">立即提交</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="whiteVisible"
      width="620px"
      :show-close="false"
      :modal-append-to-body="false"
      :before-close="handleClose">
      <div class="main">
        <i
          class="main_icon el-icon-success" />
        <div
          class="main_title">
          提交成功</div>
        <div
          class="main_text">
          请耐心等待，我们的工作人员将尽快与您联系~
        </div>
        <el-button
          class="main_btn"
          type="primary"
          @click="whiteVisible = false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { HTTP_URI } from '@/api/index'
import { mapGetters } from 'vuex'
export default {
  name: 'HomeView',
  components: {},
  data() {
    return {
      phone: false,
      blueVisible: false,
      whiteVisible: false,
      form: {}
    }
  },
  computed: {
    ...mapGetters(['blueFlag'])
  },
  watch: {
    // 监听弹窗状态
    blueFlag(val) {
      this.form = {}
      this.blueVisible = val
    }
  },
  mounted() {
    this.phone = JSON.parse(window.localStorage.getItem('phone'))
  },
  methods: {
    // 提交表单
    submitForm() {
      if (!this.form.name) {
        this.$message.warning('请输入您的姓名')
        return false
      }
      if (!this.form.phone) {
        this.$message.warning('请输入您的手机号')
        return false
      }
      if (!/^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/.test(this.form.phone)) {
        this.$message.warning('您的手机号格式错误，请重新输入')
        return false
      }
      axios.post(HTTP_URI.save, this.form).then(res => {
        if (res.data.code === 200) {
          this.$store.dispatch('set_blueFlag', false)
          this.whiteVisible = true
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    handleClose() {
      this.$store.dispatch('set_blueFlag', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.title_box {
  height: 140px;
  width: 100%;
  background: url(@/assets/d1.png) no-repeat center;
  padding-left: 60px;
  padding-top: 28px;

  .title_text {
    font-size: 32px;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
  }
}
.title_box.phone {
  border-radius: 15px 15px 0 0;
  height: 120px;
  .title_text {
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    line-height: 36px;
  }
}
.body_text {
  color: #3b7bfc;
  font-size: 12px;
  margin-bottom: 20px;
}
.body_text.phone {
  margin-bottom: 10px;
}
.btn_box.phone {
  margin-top: 10px;
}
.btn_box {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  .body_btn {
    width: 180px;
    height: 56px;
    background: #3b7bfc;
    border-radius: 6px 6px 6px 6px;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;
  }
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  .main_icon {
    font-size: 120px;
    color: #3b7bfc;
    margin-bottom: 30px;
  }
  .main_title {
    font-size: 32px;
    font-weight: 500;
    color: #3b7bfc;
    line-height: 32px;
    margin-bottom: 20px;
  }
  .main_text {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 14px;
    margin-bottom: 40px;
  }
  .main_btn {
    width: 180px;
    height: 56px;
    background: #3b7bfc;
    border-radius: 6px 6px 6px 6px;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;
  }
}
// .el-dialog ------------------------------------------
.dialog_box ::v-deep .el-dialog {
  height: 424px !important;
  background: linear-gradient(180deg, #ebf2ff 0%, #ffffff 25%, #ffffff 100%);
  border-radius: 20px 20px 20px 20px !important;
}
.dialog_box ::v-deep .el-dialog__header {
  padding: 0 !important;
}
.dialog_box ::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: #d2e1ff;
}
.dialog_box ::v-deep .el-dialog__headerbtn {
  font-size: 18px;
}
.dialog_box ::v-deep .el-input__inner {
  border: 1px solid #edf3ff;
}
.dialog_box ::v-deep .el-textarea__inner {
  border: 1px solid #edf3ff;
  font-family: 'Source_font';
}
.dialog_one ::v-deep .el-dialog__body {
  padding: 30px 60px;
}
</style>
