/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: 王东润
 * @Date: 2023-11-06 14:42:24
 * @LastEditors: smallWhite
 * @LastEditTime: 2024-03-16 11:31:12
 * @FilePath: /yxb-gw/src/router/index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/AboutView.vue')
  },
  {
    path: '/programme',
    name: 'programme',
    component: () => import('@/views/programmeView.vue')
  },
  {
    path: '/train',
    name: 'train',
    component: () => import('@/views/train.vue')
  },
  {
    path: '/course',
    name: 'course',
    component: () => import('@/views/courseView.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})
router.beforeEach((to, from, next) => {
  document.title = '亿学宝云'
  next()
})

export default router
