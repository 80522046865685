<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2024-03-16 11:40:22
 * @LastEditors: smallWhite
 * @LastEditTime: 2024-03-21 16:17:20
 * @FilePath: /yxb-gw/src/components/navMenu_mobile.vue
-->
<template>
  <div class="navs">
    <div class="nav_main">
      <div class="logo"
        @click="goHome">
        <img
          src="@/assets/logo1.png"
          alt=""
          style="height: 30px;">
      </div>

      <div class="nav_list"
        style="padding-right: 20px;">
        <div
          style="width:100px">
          <el-row
            :gutter="10">
            <el-col :span="12"
              v-for="(item,index) in lists"
              :key="index">
              <el-popover
                placement="bottom"
                width="230"
                trigger="hover">
                <div>
                  <h3
                    style="text-align: center;">
                    {{ index == 0?'商务合作':'客服热线' }}
                  </h3>
                </div>
                <div
                  class="popover_box">

                  <div
                    class="popover_box_item"
                    v-for="(child,e) in item.zp"
                    :key="e">
                    <img
                      :src="child.url"
                      style="width: 90px;">
                    <div>
                      {{child.name}}
                      <div
                        v-for="p in child.phone"
                        :key="p">
                        {{ p }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  slot="reference"
                  class="items_mobile">
                  <img
                    class="items_mobile_icon"
                    :src="item.icon"
                    alt="">
                  <div
                    class="items_mobile_text">
                    {{ item.text }}
                  </div>
                </div>
              </el-popover>
            </el-col>
          </el-row>
        </div>
        <i :class="drawer?'el-icon-close opens':'el-icon-s-fold aa'"
          @click="drawer = !drawer"></i>
      </div>
    </div>
    <div
      class="menu_mobile_box"
      :class="drawer?'open':''">
      <el-menu
        style="width: 100%;"
        :default-active="active"
        @select="handleSelect"
        class="el-menu-vertical-demo">
        <template
          v-for="(item,index) in list">
          <template
            v-if="!item.children">
            <el-menu-item
              :index="item.name"
              :key="index">
              <span
                slot="title">{{ item.title }}</span>
            </el-menu-item>
          </template>
          <template
            v-if="item.children">
            <el-submenu
              :key="index">
              <template
                slot="title">{{ item.title }}</template>
              <el-menu-item
                v-for="(child,j) in item.children"
                :key="j"
                :index="child.name">{{ child.title }}</el-menu-item>
            </el-submenu>
          </template>
        </template>
      </el-menu>
      <div
        style="position: absolute; width: 100%;bottom: 0;left: 0;background: #fafafa;padding: 20px;">
        <div
          style="font-size: 18px;text-align: center;margin-bottom: 10px;">
          APP下载</div>
        <el-row :gutter="10">
          <el-col :span="12">
            <img
              src="@/assets/xl_icon.png"
              style="width: 100%;">
            <div
              style="text-align: center;width: 100%;">
              学历平台APP</div>
          </el-col>
          <el-col :span="12">
            <img
              src="@/assets/fxl_icon.png"
              style="width: 100%;">
            <div
              style="text-align: center;width: 100%;">
              非学历平台APP</div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      active: 'home',
      scrollTop: 0,
      drawer: false,
      // 移入背景
      hoverFlag: false,
      direction: 'rtl',
      list: [
        {
          title: '首页',
          name: 'home'
        },
        {
          title: '产品解决方案',
          name: 'prodect',
          children: [
            {
              title: '学历继续教育服务平台',
              name: 'programme'
            },
            {
              title: '职学宝培训平台',
              name: 'train'
            }
          ]
        },
        {
          title: '课程服务',
          name: 'course'
        },
        {
          title: '关于我们',
          name: 'about'
        }
      ],
      lists: [
        {
          text: '商务',
          icon: require('@/assets/t3.png'),
          type: 1,
          zp: [
            {
              url: require('@/assets/he.png'),
              phone: ['13304019690'],
              name: '何经理'
            },
            {
              url: require('@/assets/xyy.png'),
              name: '咸经理',
              phone: ['13304157070']
            }
          ]
        },
        {
          text: '客服',
          icon: require('@/assets/t2.png'),
          type: 1,
          zp: [
            {
              url: require('@/assets/kf.png'),
              phone: ['400-168-0910', '15104150910']
            }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['activeName'])
  },
  watch: {
    $route(val) {
      this.active = val.name
    },
    // 监听激活路由name
    activeName: {
      handler(val) {
        this.active = val
      },
      immediate: true
    }
  },
  mounted() {
    // 监听滚动条距离
    this.$nextTick(function () {
      window.addEventListener('scroll', this.handleScroll)
    })
  },
  methods: {
    handleSelect(key) {
      this.$store.dispatch('set_activename', key)
      this.$router.push({ name: key })
      this.drawer = false
    },
    // 跳转首页
    goHome() {
      this.$store.dispatch('set_activename', 'home')
      this.$router.push({ name: 'home' })
    },
    // 赋值滚动条距离
    handleScroll() {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      this.scrollTop = scrollTop
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile {
  .navs {
    z-index: 99;
    height: 60px;
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    position: fixed;
    top: 0;
    left: 0;
    border-bottom: 1px solid #f2f2f2;
    .nav_main {
      margin: 0 auto;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo {
        width: 100px;
        height: 30px;
        padding-left: 20px;
        cursor: pointer;
        background-size: 100%;
      }
    }
  }
}
.items_mobile {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding-right: 10px;
  border-right: 1px solid #e6e6e6;
  .items_mobile_icon {
    width: 24px;
    margin-bottom: 0px;
  }
  .items_mobile_text {
    font-size: 12px;
  }
}
.popover_box {
  display: flex;
  justify-content: center;
  align-items: center;
  .popover_box_item {
    margin: 5px;
    text-align: center;
  }
}
</style>
<style>
.el-drawer {
  width: 250px !important;
}
.el-menu-vertical-demo {
  border-right: 0 !important;
}
.el-menu-item.is-active {
  border-bottom: 2px solid #ff616d !important;
}
.el-col:last-child .items_mobile {
  border: 0 !important;
}
.nav_list {
  display: flex;
  align-items: center;
}
.menu_mobile_box {
  padding: 0px 20px;
  position: relative;
  /* height: calc(100vh - 60px); */
  height: 0;
  overflow-y: hidden;
  background: #ffffff;
  transition: all 0.5s ease-out;
}
.menu_mobile_box.open {
  padding: 10px 20px;
  height: calc(100vh - 59px);
  overflow-y: hidden;
}

.el-submenu__title,
.el-menu-item {
  border-bottom: 1px solid #e6e6e6;
}
.el-menu-item:last-child {
  border-bottom: 0px solid #e6e6e6;
}
.opens {
  transform: rotate(180deg);
  transition: all 0.5s ease-out;
}
.menu_titles {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  margin-top: 40px;
}
</style>