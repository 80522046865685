/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: 王东润
 * @Date: 2023-11-06 14:42:24
 * @LastEditors: smallWhite
 * @LastEditTime: 2024-03-21 16:31:37
 * @FilePath: /yxb-gw/src/store/index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 导航name
    activeName: sessionStorage.getItem('activeName') || 'home',
    // 蓝色弹窗
    blueFlag: false,
    // 白色弹窗
    whiteFlag: false,
    // 是否是手机端
    isMobile: false
  },
  getters: {
    // 导航name
    activeName: (state) => state.activeName,
    // 蓝色弹窗
    blueFlag: (state) => state.blueFlag,
    // 白色弹窗
    whiteFlag: (state) => state.whiteFlag,
    isMobile :(state) => state.isMobile 
  },
  mutations: {
    // 导航name
    SET_ACTIVENAME (state, val) {
      state.activeName = val
      sessionStorage.setItem('activeName', val)
    },
    // 蓝色弹窗
    SET_BLUEFLAG (state, val) {
      state.blueFlag = val
    },
    SET_ISMOBILE (state, val) {
      state.isMobile = val
    },
    // 白色弹窗
    SET_WHITEFLAG (state, val) {
      state.whiteFlag = val
    }
  },
  actions: {
    // 导航name
    set_activename ({ commit }, val) {
      commit('SET_ACTIVENAME', val)
    },
    SET_ISMOBILE (state, val) {
      state.isMobile = val
    },
    // 蓝色弹窗
    set_blueFlag ({ commit }, val) {
      commit('SET_BLUEFLAG', val)
    },
    // 白色弹窗
    set_whiteFlag ({ commit }, val) {
      commit('SET_WHITEFLAG', val)
    }
  },
  modules: {
  }
})
