let baseUrl
switch (process.env.NODE_ENV) {
  case 'development':
    // baseUrl = 'http://192.168.70.181:8088'
    baseUrl = 'http://oa.yxbyun.com/admin'
    break
  case 'production':
    baseUrl = 'http://oa.yxbyun.com/admin'
    break
}
export const HTTP_URI = {
  save: baseUrl + '/trial/save'
}
export default HTTP_URI
